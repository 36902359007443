import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/verify',
        name: 'verify-list',
        component: () => import('@/views/modules/verify/verify-list/VerifyList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'checker'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Lista de formularios',
                active: true
            }],
            requiresAuth: true
        }
    }
]