import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/providers',
        name: 'providers-list',
        component: () => import('@/views/modules/providers/providers-list/ProvidersList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Proveedores',
            breadcrumb: [{
                text: 'Lista de proveedores',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/providers/create',
        name: 'providers-create',
        component: () => import ('@/views/modules/providers/providers-store/ProvidersStore.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Proveedores',
            breadcrumb: [{
                text: 'Agregar proveedor',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/providers/:id/edit',
        name: 'providers-edit',
        component: () => import ('@/views/modules/providers/providers-edit/ProvidersEdit.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Proveedores',
            breadcrumb: [{
                text: 'Editar proveedor',
                active: true
            }],
            requiresAuth: true
        }
    }
]