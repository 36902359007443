import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/reports/guarantee-fund',
        name: 'reports-guarantee-fund',
        component: () => import('@/views/modules/reports/GuaranteeFund.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Reportes',
            breadcrumb: [{
                text: 'Fondo de garantía',
                active: true
            }],
            requiresAuth: true
        }
    }
]