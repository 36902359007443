import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/forms/:id/edit',
        name: 'forms-edit',
        component: () => import ('@/views/modules/forms/forms-edit/FormsEdit.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Formulario',
            breadcrumb: [{
                text: 'Editar valores',
                active: true
            }],
            requiresAuth: true
        }
    }
]